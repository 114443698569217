<template>
  <v-app-bar
    app
    :color="!$vuetify.breakpoint.mobile ? 'grey' : 'transparent'"
    :id="[$vuetify.breakpoint.mobile ? 'nav_mobile' : 'nav']"
  >
    Admin Bereich
    <v-app-bar-nav-icon
      v-if="$vuetify.breakpoint.mobile"
      @click="mobileMenu = !mobileMenu"
    >
    </v-app-bar-nav-icon>
    <ul
      :class="[$vuetify.breakpoint.mobile ? 'mobileNav' : 'nav-links']"
      v-if="!($vuetify.breakpoint.mobile && !mobileMenu)"
      @click="mobileMenu = false"
    >
      <li class="links">
        <v-btn text color="dark-grey" :to="{ name: 'Home' }">zurück</v-btn>
      </li>
      <li class="links">
        <v-btn text color="dark-grey" :to="{ name: 'AdminText' }">Texte</v-btn>
      </li>
      <li class="links">
        <v-btn text color="dark-grey" :to="{ name: 'AdminMarkets' }"
          >Märkte</v-btn
        >
      </li>
      <!-- <li class="links">    
                <v-btn
                    text
                    color="dark-grey" 
                    :to="{name: 'AdminGallery'}"
                    >
                    Galerie</v-btn>
            </li>
            <li class="links">
                <v-btn
                    text
                    color="dark-grey"
                    :to="{name: 'AdminProducts'}"
                    >Produkt Bilder</v-btn>
            </li> -->
      <li class="links">
        <v-btn 
          text 
          color="dark-grey" 
          :to="{ name: 'AdminProfile' }" 
        >
          Profil
        </v-btn>
      </li>
      <li class="links">
        <v-btn text @click="logout">
          logout
        </v-btn>
      </li>
    </ul>
  </v-app-bar>
</template>

<script>
import utils from "@/utils";

export default {
  name: "AdminNav",
  data() {
    return {
      mobileMenu: false
    };
  },
  mounted() {
    console.log(this.$vuetify.breakpoint.width);
    console.log("mobile:" + this.$vuetify.breakpoint.mobile);
  },
  methods: {
    logout() {
      utils.logout();
    }
  }
};
</script>

<style scoped>
#nav {
  display: flex;
  justify-content: center;
  background: rgb(129, 0, 0) !important;
  color: white;
}
#nav_mobile {
  display: block;
  box-shadow: none;
  background: rgb(129, 0, 0) !important;
  color: white;
}

.nav-links {
  display: flex;
  list-style: none;
  text-decoration: none;
}

.links:hover {
  text-decoration: underline;
}

#nav a {
  color: #ffffff;
  padding: 0 10px;
  text-decoration: none;
}

#nav a.router-link-exact-active {
  color: #ca0000;
  text-decoration: none;
}

.mobileNav {
  display: block;
  background-color: darkgray;
  position: absolute;
  top: 50px;
  list-style-type: none;
}
.v-toolbar__content {
  left: 0px;
}
</style>
