<template>
  <div class="adminBlock">
    <admin-nav />
    <router-view :key="$route.path" />
    <!-- <h1> Admin bereich </h1>
    <v-container class="text-center" height="500" justify="center">
        <h2> Textblöcke </h2>
        <div v-for="tb in texts" :key="tb.slug">
            <textblock-form :textBlock="tb" />
        </div>
    </v-container>

    <v-container class="text-center" height="500" justify="center">
        <h2> Märkte </h2>
        <div v-for="markt in markets" :key="markt.id">
            <admin-calendar :marktData="markt" />
        </div>
    </v-container>

    <v-container class="text-center" height="500" justify="center">
        <h2>Bilder Slideshow</h2>
        <admin-gallery :imageSlug="gallery"/>
    </v-container>

    <v-container class="text-center" height="500" justify="center">
        <h2>Produkt bilder</h2>
        <admin-gallery :imageSlug="gallery"/>
    </v-container>

    <imageUpload/> -->
  </div>
</template>

<script>
// import imageUpload from "@/adminComponents/imageUpload.vue"
// import TextblockForm from "@/adminComponents/AdminTextField.vue"
// import AdminCalendar from '../adminComponents/AdminCalendar.vue'
// import AdminGallery from '../adminComponents/AdminGallery.vue'
import AdminNav from "@/adminComponents/AdminNav.vue";

export default {
  name: "admin",
  data() {
    return {};
  },
  components: {
    AdminNav
  },
  methods: {},
  mounted() {}
};
</script>

<style>
.adminCard {
  padding: 10px;
  margin-bottom: 10px;
}

.adminBlock {
  justify-content: center;
}

img {
  border-radius: 10px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
h1 {
  text-align: center;
}
p {
  text-align: center;
}
div {
  text-align: center;
}
</style>
